<template>
  <div class="smdsContainer">
    <div class="box">
      <div class="title">
        <div class="logo"></div>
        <span class="text">EASE CLEANER</span>
      </div>
      <div class="content">
        <div class="left"></div>
        <div class="right">
          <div class="header">
            <h4 class="title">EASE CLEANER</h4>
            <p class="desc">Ease Cleaner is an essential assistant for mobile phone management, a simple and powerful mobile phone cleaning tool!</p>
          </div>
          <div class="bottom">
            <h4 class="desc">Lacking space for new apps and photos? Just clean app caches and junk files, boost your phone with one click!</h4>
            <p class="desc">With our professional cleaner, you can also free up much more space from cleaning cache data from social apps without worrying about deleting the wrong files.</p>
          </div>
          <a href="https://play.google.com/store/apps/details?id=com.ease.cleaner">
            <div class="download">
              <i class="icon-android"></i>
              <span class="text">Android下载</span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="footer">
      <!-- <div class="title">
        <div class="bg">ABOUT US</div>
        <div class="text">公司介绍</div>
      </div> -->
      <div class="content">Copyright markups. All right reserved. Sino Elite Network Co.,Limited</div>

      <!-- <div class="bottom">
        <h4 class="domainInfo"><a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2020118034号</a>|广州琨玉网络科技有限公司</h4>
        <p class="desc">广州市天河区体育西路103号之一2606房自编A16房</p>
      </div> -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>

<style scoped lang="scss">
  .smdsContainer {
    .box {
      padding: 70px 180px 110px 320px;
      box-sizing: border-box;
      display: block;
      width: 100%;
      height: 100%;
      background: url('../../assets/baseCleaner/bbg.png') no-repeat center center;
      background-size: cover;
      .title {
        margin-bottom: 48px;
        display: flex;
        align-items: center;
        .logo {
          margin-right: 16px;
          display: inline-block;
          height: 77px;
          width: 77px;
          background: url('../../assets/baseCleaner/logo.png') no-repeat center center;
          background-size: cover;
        }
        .text {
          font-size: 36px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.89);
          line-height: 50px;
        }
      }
      .content {
        display: flex;
        .left {
          margin-left: 65px;
          margin-right: 166px;
          display: block;
          width: 648px;
          height: 673px;
          background: url('../../assets/baseCleaner/bg.png') no-repeat center center;
          background-size: contain;
        }
        .right {
          flex: 1;
          .header {
            margin-bottom: 54px;
            .title {
              margin-bottom: 15px;
              font-size: 48px;
              font-weight: 500;
              color: #fff;
              line-height: 67px;
              letter-spacing: 1px;
            }
            .desc {
              font-size: 28px;
              font-weight: 500;
              color: #fff;
              line-height: 40px;
            }
          }
          .bottom {
            margin-bottom: 47px;
            .title {
              margin-bottom: 12px;
              font-size: 16px;
              font-weight: 500;
              color: #fff;
              line-height: 40px;
            }
            .desc {
              font-size: 16px;
              font-weight: 500;
              color: #fff;
              line-height: 26px;
            }
          }
          .download {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 226px;
            height: 60px;
            background: url('../../assets/baseCleaner/btn.png') no-repeat center center;
            background-size: contain;
            cursor: pointer;
            .icon-android {
              margin-right: 15px;
              display: inline-block;
              width: 32px;
              height: 38px;
              background: url('../../assets/baseCleaner/android.png') no-repeat center center;
              background-size: cover;
            }
            .text {
              font-size: 22px;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 30px;
            }
          }
        }
      }
    }
    .footer {
      padding: 70px 412px 48px 420px;
      box-sizing: border-box;
      margin-left: -4px;
      background: #2B2B2A;
      .title {
      position: relative;
       display: flex;
       justify-content: center;
       align-items: center;
       .bg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          color: #EEEEEE;
          font-size: 49px;
          font-weight: bold;
          z-index: 0;
        }
         .text {
          position: relative;
          top: 10px;
          font-size: 28px;
          font-weight: 500;
          color: #000000;
          line-height: 40px;
        }
      }
      .content {
        margin-top: 24px;
        margin-bottom: 58px;
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        color: #fff;
        line-height: 28px;
      }
      .bottom {
        .domainInfo {
          text-align: center;
          margin: 0;
          margin-bottom: 6px;
          font-size: 16px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.89);
          line-height: 22px;
          a {
            color: rgba(0, 0, 0, 0.89);
          }
        }
        .desc {
          text-align: center;
          margin-bottom: 48px;
          font-size: 16px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.6);
          line-height: 22px;
        }
      }
    }
    a {
      display: inline-block;
      text-decoration: none;
    }
  }

</style>
